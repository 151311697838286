import { HOME_HOSTS_FETCH_LIMIT } from 'consts/Firebase'
import { collection, getDocs, limit, orderBy, query, where } from 'firebase/firestore'
import { db } from 'services/FirebaseClient'
import { extractPublicInfoFromHostRecord } from 'utils/publicInfoExtractor'

export const getAllHostsWFutureEvents = async () => {
  // console.log('📌 @getAllHostsWFutureEvents')
  const allHosts = []

  const q = query(
    collection(db, 'users'),
    where('userType', '==', 'host'),
    where('summary.upcomingPublicEvents', '>', 0), // hosts with atleast 1 ticket to sell,
    orderBy('summary.remaining', 'desc'),
    limit(HOME_HOSTS_FETCH_LIMIT)
  )

  try {
    const snapShot = await getDocs(q)
    snapShot.forEach(doc => allHosts.push(extractPublicInfoFromHostRecord(doc.data())))
  } catch (err) {
    console.log('🚨 Error getting documents:', err)
    console.error('🚨 Error getting documents:', err)
  }

  return allHosts
}
