// import { HeroBeams } from 'components/organisms/HeroBeams'
import BrowseByHost from 'components/organisms/dashboard/BrowseByHost'
import { ROUTES } from 'consts'
import dynamic from 'next/dynamic'

// import { getAllFutureEventsonServer } from 'services/server/Events'
// import { getAllHostsWFutureEvents } from 'services/server/Hosts'

// const ssr = { ssr: false }

const SignUpSuggestionUI = dynamic(() => import('components/organisms/Auth/SignUpSuggestionUI'))
const BrowseByCategory = dynamic(() => import('components/organisms/Events/BrowseByCategory'), {
  ssr: false,
})
const HeroEventsCarousel = dynamic(() => import('components/organisms/HeroEventsCarousel'), {
  ssr: false,
})
const InstagramFollowWidget = dynamic(() => import('components/organisms/InstagramFollowWidget'))
const MarketingStats = dynamic(() => import('components/organisms/MarketingStats'), { ssr: false })
const MediaOverlay = dynamic(() => import('components/organisms/MediaOverlay'))

// #############################################################################
// #                           SERVER SIDE CODE                                #
// #############################################################################
// export const getServerSideProps = async () => {
//   console.log('@getServerSideProps - Homepage')
//   // const cache = getGlobalData()
//   let allEvents = await getAllFutureEventsonServer(false)
//   let allHosts = await getAllHostsWFutureEvents()
//   // let allEvents = cache?.allEvents || []
//   // let allHosts = cache?.allHosts || []
//   console.log('allEvents', allEvents.length)
//   console.log('allHosts', allHosts.length)

//   // if (!allEvents?.length) return { notFound: true }

//   return {
//     props: {
//       data: {
//         allEvents,
//         allHosts,
//       },
//     },
//   }
// }

// TODO: make this work
// export const getServerSideProps = async ({ params }) => {
//   console.log('@getServerSideProps - Homepage')

//   const globalData = await getGlobalData()
//   let allEvents = globalData?.allEvents || []

//   return allEvents ? { props: { data: { allEvents } } } : { notFound: true }
// }

// Not -working
// HomePage.getInitialProps = wrapper.getInitialPageProps(store => async () => {
//   console.log('@getInitialProps w/Wrapper - Homepage')

//   await store.dispatch(getAllFutureEventsonServer())

//   return { name: 'getAllFutureEventsonServer' }
// })

// #############################################################################
// #                           CLIENT SIDE CODE                                #
// #############################################################################
const HomePage = () => {
  return (
    <>
      {/* <Image src="/icons/sessami-logo-pink.svg" objectFit="cover" width="800" height="800"  className="absolute opacity-10 z-0 right-4"/> */}
      {/* <HeroOverlayImage /> */}
      {/* <HeroHome events={events} /> */}
      {/* <HeroBeams /> */}

      <HeroEventsCarousel title='Trending Events' className='col-span-full' />
      <BrowseByCategory title='Top Categories' className='col-span-full' />

      <BrowseByHost title='Top Hosts' className='col-span-full' />

      {/* <DiscoverCities /> */}
      <MarketingStats />

      <MediaOverlay
        image={{
          url: '/assets/womenInStore.webp',
          alt: 'Women in a store',
          width: 920,
          height: 520,
        }}
        cta={{
          preTitle: '',
          title: 'Ready to host your next event??',
          postTitle:
            'The local event discovery marketplace that gives you the tools to grow your audience.',
          ctaOnClick: () => router.push(ROUTES.HOSTING.FOR_HOSTS),
          ctaText: 'Host my event',
        }}
        includeMeetScheduleLink
      />
      <InstagramFollowWidget />
      <SignUpSuggestionUI />
    </>
  )
}

export default HomePage
