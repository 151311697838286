'use client'

import Button from 'components/atoms/Button'
import HostCardSkeleton from 'components/atoms/HostCardSkeleton'
import GridContainer from 'components/containers/GridContainer'
import ReusableGrid from 'components/containers/ReusableGrid'
import { BrowseByHostsCard } from 'components/molecules/BrowseByHosts/BrowseByHostsCard'
import Heading from 'components/molecules/Heading'
// import withErrorBoundary from 'components/templates/WithErrorBoundary'
import useIsMobile from 'hooks/useIsMobile'
import React, { useEffect, useState } from 'react'
import { getAllHostsWFutureEvents } from 'services/client/buyer/hosts'

// import { readPublicEventsCountByHostId } from 'services/client/common/events'

// The maximum number of hosts to show in smaller devices
const HOSTS_LIMIT = 6

const BrowseByHost = ({ title = 'Browse By Hosts' }) => {
  const isMobile = useIsMobile()

  // const [sortedHosts, setSortedHosts] = useState([])
  const [hostsToShow, setHostsToShow] = useState(HOSTS_LIMIT)
  const [isLoading, setIsLoading] = useState(true)
  const [loadMoreVisible, setLoadMoreVisible] = useState(false)
  const [allHosts, setAllHosts] = useState([])

  useEffect(() => {
    asyncEffect()
  }, [])

  // useEffect(() => {
  //   setLoadMoreVisible(allHosts.length > hostsToShow)
  // }, [])

  const asyncEffect = async () => {
    const allHosts = await getAllHostsWFutureEvents()

    const hosts = allHosts?.map(host => {
      const hostId = host?.id
      const hostName = host?.hostTitle || 'N&D'
      const hostImage = host?.profileImage
      const verifiedHost = host?.isVerified || false
      const summary = host?.summary

      return { hostId, hostName, hostImage, verifiedHost, summary }
    })

    setAllHosts(hosts)
    setIsLoading(false)
    setLoadMoreVisible(allHosts.length > hostsToShow)
  }

  // const getHostEventsCount = async hosts => {
  //   const getHostEvents = hosts.map(async host => {
  //     const eventsCount = await readPublicEventsCountByHostId(host.hostId, true)
  //     return {
  //       ...host,
  //       eventsCount,
  //     }
  //   })

  //   return Promise.all(getHostEvents)
  // }

  const loadMoreHosts = () => {
    const newHostsToShow = hostsToShow + HOSTS_LIMIT
    setHostsToShow(newHostsToShow)

    // Check if there are more hosts to load
    setLoadMoreVisible(newHostsToShow < allHosts.length)
  }

  // useEffect(() => {
  //   getHostEventsCount(hosts)
  //     .then(results => {
  //       const sortedHosts = results
  //         .filter(item => item.eventsCount > 0)
  //         .sort((a, b) => b.eventsCount - a.eventsCount)

  //       setSortedHosts(sortedHosts)

  //       // Check if there are more hosts to load initially
  //       setLoadMoreVisible(sortedHosts.length > hostsToShow)
  //     })
  //     .catch(error => {
  //       console.error(error)
  //     })
  //     .finally(() => setIsLoading(false))
  // }, [allHosts])

  // if (!allHosts?.length && !isLoading) return <div>No hosts found</div>

  return (
    <ReusableGrid>
      <Heading title={title} className='col-span-full' />

      {/* Show Skeleton while fetching hosts */}
      {isLoading && (
        <GridContainer className='col-span-full'>
          {Array.from({ length: HOSTS_LIMIT }, (_, index) => index).map(value => (
            <HostCardSkeleton key={value} />
          ))}
        </GridContainer>
      )}

      <>
        {allHosts.slice(0, isMobile ? hostsToShow : allHosts?.length).map(host => (
          <BrowseByHostsCard key={host.hostId} host={host} isMobile={isMobile} />
        ))}
      </>

      {isMobile && (
        <GridContainer className='col-span-full mt-6'>
          {loadMoreVisible && (
            <Button
              className='col-span-2 col-start-1 block md:col-start-1 lg:col-start-5'
              primary
              onClick={loadMoreHosts}>
              Load More Hosts
            </Button>
          )}
        </GridContainer>
      )}
    </ReusableGrid>
  )
}

// export default withErrorBoundary(BrowseByHost, 'BrowseByHost')
export default BrowseByHost
