import React from 'react'

const HostCardSkeleton: React.FC = () => {
  return (
    <div className='col-span-2 flex aspect-square flex-col items-center justify-start lg:col-span-3 xl:col-span-2'>
      <div className='skeleton aspect-square w-11/12'></div>
    </div>
  )
}

export default HostCardSkeleton
