import cn from 'clsx'
import Image from 'components/atoms/Image'
import VerifiedBadge from 'components/organisms/Badge/VerifiedBadge'
import ROUTES from 'consts/Routes'
import Link from 'next/link'
import React from 'react'

export const BrowseByHostsCard = ({ host, isMobile }) => {
  const totalUpcomingPublicEvents = host?.summary?.upcomingPublicEvents

  return (
    <Link
      className={cn(
        'col-span-2 flex aspect-square w-11/12 flex-col items-center justify-start text-center lg:col-span-3 xl:col-span-2',
        isMobile || 'host-card'
      )}
      href={ROUTES.BOOKING_WIDGET.replace(':hostId', host.hostId)}>
      <div className='host-card__inner custom-transition-a relative aspect-square w-full'>
        <div className='host-card__front absolute flex h-full w-full items-end justify-center overflow-hidden rounded-xl bg-base-200'>
          <Image objectFit='cover' src={host.hostImage} alt='host profile' layout='fill' />

          {host?.verifiedHost && (
            <div className='absolute right-2 top-2 z-20'>
              <VerifiedBadge />
            </div>
          )}
          {/* <div className='absolute bottom-2 left-2 right-2 flex items-center justify-center gap-1 rounded-lg border bg-secondary-content px-2 py-0.5 text-primary shadow-lg lg:hidden'>
            <span className='flex-[4] overflow-hidden truncate font-semibold'>{host.hostName}</span>
            <div className='badge badge-secondary flex-1'>+{host.eventsCount}</div>
          </div> */}
        </div>

        <div className='host-card__back absolute hidden h-full w-full flex-col items-center justify-center gap-1 rounded-xl bg-secondary px-2 py-1 lg:flex'>
          <p className='text-secondary-content'>
            {totalUpcomingPublicEvents + ' upcoming '}{' '}
            {totalUpcomingPublicEvents > 1 ? 'events' : 'event'}
          </p>

          <h2 className='max-w-36 overflow-hidden truncate rounded-xl bg-secondary-content px-2 py-1 text-sm font-semibold text-primary'>
            {host.hostName}
          </h2>
        </div>
      </div>
    </Link>
  )
}
